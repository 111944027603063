import homeBanner from "../images/home-banner.png";
import homeBannerWP from "../images/home-banner.webp";
import homeBannerLarge from "../images/home-banner-lg.png";
import homeBannerLargeWP from "../images/home-banner-lg.webp";
import aboutBanner from "../images/about-banner.png";
import aboutBannerLarge from "../images/about-banner-lg.png";
import aboutBannerWP from "../images/about-banner.webp";
import aboutBannerLargeWP from "../images/about-banner-lg.webp";
import salesBanner from "../images/sales-banner.png";
import salesBannerLarge from "../images/sales-banner-lg.png";
import salesBannerWP from "../images/sales-banner.webp";
import salesBannerLargeWP from "../images/sales-banner-lg.webp";
import salesBannerHome from "../images/sales-banner.png";
import salesBannerLargeHome from "../images/sales-banner-lg.png";
import salesBannerWPHome from "../images/sales-banner.webp";
import salesBannerLargeWPHome from "../images/sales-banner-lg.webp";
import managementBanner from "../images/management-banner.png";
import managementBannerLarge from "../images/management-banner-lg.png";
import managementBannerWP from "../images/management-banner.webp";
import managementBannerLargeWP from "../images/management-banner-lg.webp";
import managementBannerHome from "../images/management-banner-1.png";
import managementBannerLargeHome from "../images/management-banner-1-lg.png";
import managementBannerWPHome from "../images/management-banner-1.webp";
import managementBannerLargeWPHome from "../images/management-banner-1-lg.webp";
import charterBanner from "../images/charter-banner.png";
import charterBannerLarge from "../images/charter-banner-lg.png";
import charterBannerWP from "../images/charter-banner.webp";
import charterBannerLargeWP from "../images/charter-banner-lg.webp";
import charterBannerHome from "../images/charter-banner-1.png";
import charterBannerLargeHome from "../images/charter-banner-1-lg.png";
import charterBannerWPHome from "../images/charter-banner-1.webp";
import charterBannerLargeWPHome from "../images/charter-banner-1-lg.webp";
import servicesBanner from "../images/services-banner-lg.png";
import servicesBannerLarge from "../images/services-banner-lg.png";
import servicesBannerWP from "../images/services-banner.webp";
import servicesBannerLargeWP from "../images/services-banner-lg.webp";
import servicesBannerHome from "../images/services-banner-lg.png";
import servicesBannerLargeHome from "../images/services-banner-1-lg.png";
import servicesBannerWPHome from "../images/services-banner-1.webp";
import servicesBannerLargeWPHome from "../images/services-banner-1-lg.webp";
import managementIcon from "../images/icon-fullM.svg";
import starIcon from "../images/icon-star-little.svg";
import projectIcon from "../images/icon-ListManagement.svg";
import crewIcon from "../images/icon-crew.svg";
import cleaningIcon from "../images/icon-cleaning.svg";
import repairIcon from "../images/icon-repair.svg";
import shippingIcon from "../images/icon-shipping.svg";
import deliveryIcon from "../images/icon-YachtDelivery.svg";
import brokerageIcon from "../images/icon-brokerage.svg";
import buyYachtsIcon from "../images/icon-buy-yachts.svg";
import sellYachtsIcon from "../images/icon-sell-yachts.svg";
import searchIcon from "../images/icon-search-yachts.svg";

export const BLOG_MIAMI_URL = "https://blog.miamiyachting.net";

export const HOME_STEPS = [
  {
    name: "home",
    url: "top",
  },
  {
    name: "sales",
    url: "sales",
  },
  {
    name: "management",
    url: "management",
  },
  {
    name: "charters",
    url: "charters",
  },
  {
    name: "services",
    url: "services",
  },
];

export const PAGES_DATA = [
  {
    title: "Privacy Policy",
    id: "privacy-policy",
    url: "/privacy-policy",
    homeBannerImage: aboutBanner,
    homeBannerWP: aboutBannerWP,
    homeLargeBanner: aboutBannerLarge,
    homeLargeBannerWP: aboutBannerLargeWP,
    bannerImage: aboutBanner,
    bannerWP: aboutBannerWP,
    largeBanner: aboutBannerLarge,
    largeBannerWP: aboutBannerLargeWP,
  },
  {
    title: "Your one stop solution to all your yachting needs",
    id: "home",
    subtitle:
      "Our passion is yachting and our mission is to help you enjoy the yachting lifestyle through qualified assistance with the purchase & sale process and comprehensive management services solutions. Come onboard and enjoy the real yacht life.",
    url: "/about",
    homeBannerImage: homeBanner,
    homeBannerWP: homeBannerWP,
    homeLargeBanner: homeBannerLarge,
    homeLargeBannerWP: homeBannerLargeWP,
    bannerImage: homeBanner,
    bannerWP: homeBannerWP,
    largeBanner: homeBannerLarge,
    largeBannerWP: homeBannerLargeWP,
  },
  {
    title: "About Us",
    id: "about",
    url: "/about",
    homeBannerImage: aboutBanner,
    homeBannerWP: aboutBannerWP,
    homeLargeBanner: aboutBannerLarge,
    homeLargeBannerWP: aboutBannerLargeWP,
    bannerImage: aboutBanner,
    bannerWP: aboutBannerWP,
    largeBanner: aboutBannerLarge,
    largeBannerWP: aboutBannerLargeWP,
  },
  {
    title: "Yacht Sales",
    id: "sales",
    url: "/sales",
    homeBannerImage: salesBannerHome,
    homeBannerWP: salesBannerWPHome,
    homeLargeBanner: salesBannerLargeHome,
    homeLargeBannerWP: salesBannerLargeWPHome,
    bannerImage: salesBanner,
    bannerWP: salesBannerWP,
    largeBanner: salesBannerLarge,
    largeBannerWP: salesBannerLargeWP,
    items: [
      {
        icon: searchIcon,
        name: "Search for a Yacht",
        url: "https://sales.miamiyachting.net",
      },
      {
        icon: brokerageIcon,
        name: "Brokerage",
        url: "brokerage",
      },
      {
        icon: buyYachtsIcon,
        name: "Buying a Yacht",
        url: "buying-yachts",
      },
      {
        icon: sellYachtsIcon,
        name: "Selling a Yacht",
        url: "selling-yachts",
      },
    ],
  },
  {
    title: "Yacht Management",
    id: "management",
    url: "/management",
    homeBannerImage: managementBannerHome,
    homeBannerWP: managementBannerWPHome,
    homeLargeBanner: managementBannerLargeHome,
    homeLargeBannerWP: managementBannerLargeWPHome,
    bannerImage: managementBanner,
    bannerWP: managementBannerWP,
    largeBanner: managementBannerLarge,
    largeBannerWP: managementBannerLargeWP,
    items: [
      {
        icon: managementIcon,
        name: "Full management",
        url: "full-management",
      },
      {
        icon: starIcon,
        name: "Tailored management",
        url: "custom-management",
      },
      {
        icon: projectIcon,
        name: "Project management",
        url: "project-management",
      },
    ],
  },
  {
    title: "Charters",
    id: "charters",
    url: "/charters",
    homeBannerImage: charterBannerHome,
    homeBannerWP: charterBannerWPHome,
    homeLargeBanner: charterBannerLargeHome,
    homeLargeBannerWP: charterBannerLargeWPHome,
    bannerImage: charterBanner,
    bannerWP: charterBannerWP,
    largeBanner: charterBannerLarge,
    largeBannerWP: charterBannerLargeWP,
  },
  {
    title: "Services",
    id: "services",
    url: "/services",
    homeBannerImage: servicesBannerHome,
    homeBannerWP: servicesBannerWPHome,
    homeLargeBanner: servicesBannerLargeHome,
    homeLargeBannerWP: servicesBannerLargeWPHome,
    bannerImage: servicesBanner,
    bannerWP: servicesBannerWP,
    largeBanner: servicesBannerLarge,
    largeBannerWP: servicesBannerLargeWP,
    items: [
      {
        icon: crewIcon,
        name: "Professional Crew",
        url: "professional-crew",
      },
      {
        icon: cleaningIcon,
        name: "Cleaning and detailing",
        url: "clean-and-detailing",
      },
      {
        icon: repairIcon,
        name: "Installations and repairs",
        url: "installations-and-repair",
      },
      {
        icon: shippingIcon,
        name: "International Shipping & Logistics",
        shortName: "Intl. Shipping & Logistics",
        url: "international-shipping",
      },
      {
        icon: deliveryIcon,
        name: "Yacht Delivery",
        url: "yacht-delivery",
      },
    ],
  },
  {
    title: "Blog",
    id: "blog",
    url: BLOG_MIAMI_URL,
    homeBannerImage: homeBanner,
    homeBannerWP: homeBannerWP,
    homeLargeBanner: homeBannerLarge,
    homeLargeBannerWP: homeBannerLargeWP,
    bannerImage: homeBanner,
    bannerWP: homeBannerWP,
    largeBanner: homeBannerLarge,
    largeBannerWP: homeBannerLargeWP,
  },
  {
    title: "Contact Us",
    id: "contact",
    url: "/contact",
    homeBannerImage: homeBanner,
    homeBannerWP: homeBannerWP,
    homeLargeBanner: homeBannerLarge,
    homeLargeBannerWP: homeBannerLargeWP,
    bannerImage: homeBanner,
    bannerWP: homeBannerWP,
    largeBanner: homeBannerLarge,
    largeBannerWP: homeBannerLargeWP,
  },
];

export const FORMSPREE_ENDPOINT = "https://formspree.io/f/mdoyqavg";
export const IG_URL = "https://www.instagram.com/miami_yachting/?hl=en";
export const FB_URL =
  "https://www.facebook.com/pg/Miami-Yachting-1723862944578162";
export const API_URL =
  "https://afternoon-tor-08689.herokuapp.com/https://api.yatcoboss.com/api/v1";
export const DEFAULT_VALUE = "";
export const SALES_MIAMI_URL = "https://sales.miamiyachting.net";
