import React from "react";
import Autocomplete from "@mui/material/Autocomplete";

import "./SearchSelect.scss";

export const SearchSelect = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  loading
}) => {
  return (
    <label className="my-search-select">
      {label}
      <Autocomplete
        value={value}
        placeholder={placeholder}
        options={options}
        loading={loading}
        renderOption={(props, option) => {
          return (
            <li {...props} className="my-search-select__item" key={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              placeholder={placeholder}
              {...params.inputProps}
              className="my-search-select__field"
            />
          </div>
        )}
        onChange={(event, value) => onChange(value)}
        sx={{
          width: "100%",
        }}
      />
    </label>
  );
};

export default SearchSelect