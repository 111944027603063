import React from "react";

import "./stepper.scss";
import {Link} from "gatsby";

export const Stepper = ({steps = [], selected = 0, currentPage, isFirst = false}) => {
  return (
    <div className={`my-stepper ${isFirst ? "my-stepper--first" : ""}`}>
      <div className="my-stepper-steps">
        {
          steps.map((step, index) => {
            return <Link key={step.url} className={`my-stepper__step ${index === selected ? "my-stepper__step--selected" : ""}`} to={`${currentPage}#${step.url}`} />
          })
        }
      </div>
    </div>
  );
}
