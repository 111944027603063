import React from "react";
import {Helmet} from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";
import * as PropTypes from "prop-types";
import logo from "../../images/logo-blue.png";

function SEO({description, lang, meta, image: metaImage, title, pathname, keywords: metaKeywords, themeColor}) {
  const {site} = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
            twitterUsername
            keywords
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.defaultDescription;
  const image =
    metaImage ?? `${site.siteMetadata.siteUrl}${logo}`;
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;
  const imageWidth = metaImage && metaImage.width ? metaImage.width : 100;
  const imageHeight = metaImage && metaImage.height ? metaImage.height : 100;
  const titleTemplate = title ? `${title} | ${site.siteMetadata.defaultTitle}` : site.siteMetadata.defaultTitle;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleTemplate}
      link={
        canonical
          ? [
            {
              rel: "canonical",
              href: canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords
            ? site.siteMetadata.keywords.concat(metaKeywords).join(",")
            : "",
        },
        {
          property: `og:title`,
          content: titleTemplate,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${pathname || ""}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: titleTemplate,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:image:width",
          content: imageWidth,
        },
        {
          property: "og:image:height",
          content: imageHeight,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "theme-color",
          content: themeColor,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  themeColor: "#07215C"
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    external: PropTypes.bool
  }),
  pathname: PropTypes.string,
  themeColor: PropTypes.string
};

export default SEO;
