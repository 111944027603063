import React from "react";

import closeIcon from "../../images/close.svg"

import "./navbar.scss";

const Navbar = ({ isOpen, onToggleMenu, children }) => {
  return (
    <nav className={`my-navbar ${isOpen ? "my-navbar--active" : ""}`}>
      <div className="my-navbar__header">
        <button
          className="my-navbar__close"
          type="button"
          onClick={onToggleMenu}
        >
          <img src={closeIcon} alt="Toggle Menu" />
        </button>
      </div>
      {children}
    </nav>
  );
};

export default Navbar;
