import { useEffect, useState } from "react";
import { API_URL } from "../utils/constants";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Basic iALvWMqtQjpLX55yuOc9Q16QDA0+fPWTKS4mPvPNlX08VPyRzL6SfA==",
};

const useManufacturers = () => {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/Common/Builders/Searchlist?filter=`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const jsonData = await response.json();
      const transformedArray = jsonData?.map(({ BuilderID, BuilderName }) => ({
        id: BuilderID,
        label: BuilderName,
      }));

      setData(transformedArray);
    } catch (error) {
      setErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, errors, loading };
};

export default useManufacturers;
