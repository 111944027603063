import React from "react";

import "./SearchRange.scss";

const SearchRange = ({ label, minPlaceholder, maxPlaceholder, name, minValue, maxValue, onChange }) => {
  return (
    <label className="my-search-range">
      {label}
      <div className="my-search-range-fields">
        <input
          placeholder={minPlaceholder}
          name={`${name}-min`}
          value={minValue}
          onChange={(event) => { onChange(event.target.value, maxValue) }}
          type="number" />
        <input
          placeholder={maxPlaceholder}
          name={`${name}-max`}
          value={maxValue}
          onChange={(event) => { onChange(minValue, event.target.value) }}
          type="number" />
      </div>
    </label>
  );
}

export default SearchRange