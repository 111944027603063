import React from "react";

import "./button.scss";
import { Link } from "gatsby";

export const Button = ({ text, url, className = "" }) => {
  if (!url) {
    return (
      <button className={`my-button ${className}`}>{text}</button>
    );
  }
  return (
    <Link className="my-button" to={url}>{text}</Link>
  );
}
