import React, { useState } from "react";
import { Link, navigate } from "gatsby";

import "./header.scss";
import logo from "../../images/logo-white.png";
import logoWP from "../../images/logo-white.webp";
import menuIcon from "../../images/menu.svg";
import searchIcon from "../../images/icon-search-yachts.svg";
import Navbar from "../navbar/navbar";
import { IG_URL, BLOG_MIAMI_URL } from "../../utils/constants";
import { Dropdown } from "../dropdown/dropdown";
import { PAGES_DATA, SALES_MIAMI_URL } from "../../utils/constants";
import HeaderForm from "../header-form/HeaderForm";

const NavigationLinks = () => {
  return <ul className="my-navbar-list">
    <li className="my-navbar-list__link">
      <Link to="/about">About us</Link>
    </li>
    <li className="my-navbar-list__link">
      <Link to="/sales">Yacht Sales</Link>
    </li>
    <li className="my-navbar-list__link">
      <Link to="/management">Yacht management</Link>
    </li>
    <li className="my-navbar-list__link">
      <Link to="/charters">Charters</Link>
    </li>
    <li className="my-navbar-list__link">
      <Link to="/services">Services</Link>
    </li>
    <li className="my-navbar-list__link">
      <Link to={BLOG_MIAMI_URL}>Blog</Link>
    </li>
    <li className="my-navbar-list__link">
      <a href={IG_URL} target="_blank">Gallery</a>
    </li>
    <li className="my-navbar-list__link">
      <Link to="/contact">Contact us</Link>
    </li>
  </ul>
}

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const onSubmitHandler = (data) => {
    const { manufacturer, ...partialData } = data
    const standardizedData = {
      ...partialData,
      ...data.manufacturer && {
        id: data.manufacturer.id ?? null,
        label: data.manufacturer.label ?? null
      }
    }
    const queryStrings = new URLSearchParams(standardizedData).toString();
    const urlToNavigateTo = `${SALES_MIAMI_URL}?${queryStrings}`;
    window.location.href =urlToNavigateTo;
  }

  return (
    <header className="my-header">
      <Link to="/">
        <picture>
          <source srcSet={logoWP} type="image/webp" />
          <img className="my-header__logo" src={logo} alt="Miami Yachting Logo" />
        </picture>
      </Link>
      <nav className="my-header__links">
        {PAGES_DATA.slice(2).map(page => <Dropdown key={page.title} url={page.url} name={page.title} linkList={page.items} />)}
      </nav>
      <section className="my-header__search-form">
        <HeaderForm formActionHandler={onSubmitHandler} />
      </section>
      <section className="my-header__icons-wrapper">
        <button className={"my-header__search-button"}
          onClick={handleToggleForm}>
          <img className="my-header__icon" src={searchIcon} alt="Search icon" />
        </button>
        <button
          type="button"
          className="my-header__menu"
          onClick={handleToggleMenu}>
          <img src={menuIcon} alt="Toggle Menu" />
        </button>
      </section>
      <Navbar isOpen={menuOpen} onToggleMenu={handleToggleMenu} >
        <NavigationLinks />
      </Navbar>
      <Navbar isOpen={isFormOpen} onToggleMenu={handleToggleForm} >
        <HeaderForm formActionHandler={onSubmitHandler} />
      </Navbar>
    </header>
  );
}
