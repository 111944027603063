import React, { useState } from 'react'

import "./HeaderForm.scss";
import arrowRight from "../../images/arrow-right.svg";
import SearchSelect from "../search-select/SearchSelect";
import SearchRange from "../search-range/SearchRange";
import useManufacturers from '../../hooks/useManufacturers';
import { Button } from "../button/button";
import { DEFAULT_VALUE } from '../../utils/constants';

const HeaderForm = ({ formActionHandler }) => {

    const { data, loading } = useManufacturers()
    const [manufacturer, setManufacturer] = useState(null);
    const [minYear, setMinYear] = useState(DEFAULT_VALUE);
    const [maxYear, setMaxYear] = useState(DEFAULT_VALUE);
    const [minLength, setMinLength] = useState(DEFAULT_VALUE);
    const [maxLength, setMaxLength] = useState(DEFAULT_VALUE);
    const [minPrice, setMinPrice] = useState(DEFAULT_VALUE);
    const [maxPrice, setMaxPrice] = useState(DEFAULT_VALUE);

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const formData = {
            manufacturer,
            minYear,
            maxYear,
            minLength,
            maxLength,
            minPrice,
            maxPrice
        }

        formActionHandler(formData)
    }

    return (
        <form onSubmit={onSubmitHandler} className='my-form'>
            <div className="my-form__input">
                <SearchSelect
                    label="Manufacturer"
                    placeholder="Select a Manufacturer"
                    options={data || []}
                    value={manufacturer}
                    onChange={(value) => setManufacturer(value)}
                    loading={loading}
                />
            </div>
            <div className="my-form__input">
                <SearchRange
                    label="Year"
                    minPlaceholder="From"
                    maxPlaceholder="To"
                    minValue={minYear || ""}
                    maxValue={maxYear || ""}
                    onChange={(newMin, newMax) => {
                        setMinYear(newMin);
                        setMaxYear(newMax);
                    }}
                />
            </div>
            <div className="my-form__input">
                <SearchRange
                    label="Length"
                    minPlaceholder="From"
                    maxPlaceholder="To"
                    minValue={minLength || ""}
                    maxValue={maxLength || ""}
                    onChange={(newMin, newMax) => {
                        setMinLength(newMin);
                        setMaxLength(newMax);
                    }}
                />
            </div>
            <div className="my-form__input">
                <SearchRange
                    label="Price (USD)"
                    minPlaceholder="Min"
                    maxPlaceholder="Max"
                    minValue={minPrice || ""}
                    maxValue={maxPrice || ""}
                    onChange={(newMin, newMax) => {
                        setMinPrice(newMin);
                        setMaxPrice(newMax);
                    }}
                />
            </div>
            <button className="my-form-submit__desktop">
                <img src={arrowRight} alt="" />
            </button>
            <Button className="my-form-submit__mobile" text={"Search"} />
        </form>
    )
}

export default HeaderForm