import React from "react";
import { Link } from "gatsby";

import "./dropdown.scss";

export const Dropdown = ({ url, name, linkList }) => {
  if (!linkList || !linkList.length) {
    return (
      <div className="my-dropdown">
        <Link className="my-dropdown__link" to={url}>{name}</Link>
        <div className="my-dropdown__underscore" />
      </div>
    );
  }

  const getLinkURL = (link) => {
    if (link.url.indexOf("http") === 0) {
      return link.url;
    } else {
      return `${url}#${link.url}`;
    }
  };

  return (
    <div className="my-dropdown">
      <Link className="my-dropdown__link" to={url}>{name}</Link>
      <div className="my-dropdown__underscore" />
      <div className="my-dropdown-content">
        {linkList.map(link => <Link key={link.name} to={getLinkURL(link)}>{link.name}</Link>)}
      </div>
    </div>
  );
}
