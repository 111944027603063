import React from "react";
import "@fontsource/open-sans";
import "@fontsource/junge"
import "./layout.scss";
import { Footer } from "../footer/footer";
import { Header } from "../header/header";
import { Banner } from "../banner/banner";

export const Layout = ({ children, page, hasBanner = true, bannerBottomAligned = true }) => {
  return (
    <div>
      <Header />
      {hasBanner && <Banner page={page || "home"} isBottomAligned={bannerBottomAligned} />}
      <div id="layout-content" className="my-layout__content">
        {children}
      </div>
      <Footer />
    </div>
  );
}
